(function () {
  'use strict';

  angular
    .module('learning.editResource')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.learning.editResource', {
        url: '/edit-resource/:id',
        templateUrl: 'dashboard/learning/edit-resource/edit-resource.tpl.html',
        controller: 'EditResourceCtrl',
        controllerAs: 'editResource',
        roles: ['superadmin', 'admin', 'Teacher']
      });
  }
}());
